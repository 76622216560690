import { LOGIN_TYPE } from '@/constants/constants'
import type { LoginType } from '@/constants/constants'
import { authModule } from '@/stores'
import { Component, Vue } from 'vue-facing-decorator'

/**
 * 画面表示や処理で共通的に必要となる、ログイン中のユーザにまつわる種々の判定を提供します。
 * 'Vue' の代わりに 'Mixins(CurrentUserManager)' を継承することで、templateやscriptタグ内で直接利用できます。
 * 画面以外で使いたい場合は /@/libs/current-user-manager を使用すること
 *
 * 区分所有者側で利用できる機能はログイン種別（ユーザがログインしてきた入口）によって決まる
 * （LMやGCCといったロールで決まるわけではない）
 *  （例：LM）各種の登録・更新・削除が出来ない、プロフィール画像の変更は可能
 *  （例：GCC/FR/管理者業務執行者）各種の登録・更新・削除が出来ない
 * もしログイン種別より更に細かい単位で機能利用を分けたい場合（例：管理者業務執行者だけはこれやらせたい等）、別途ロールの導入を検討
 *
 * 権限制御は原則として、「〇〇でない場合」を条件とするのではなく、「✕✕である場合」を条件として書くようにします。
 * これは権限の種別が追加された場合に、新規の権限が初期状態でアクションの対象になってしまうことを避けるためです。
 * 「使えるべきなのに使えない」はまだよいですが、「使えないべきなのに使える」は重大なインシデントに繋がる危険性があります。
 *   e.g.
 *   あるボタンを利用できない新しい権限Xが追加された時を考えます。
 *   特定権限以外が利用できるボタンを置く際、
 *
 *     <sm-btn v-if="!$isHoge" />
 *       ---->  権限Xはボタンを利用できてしまう。特にその機能が個人情報等を扱う場合に、改修が漏れるととても危険
 *
 *     <sm-btn v-if="$isHoge || $isFuga" />
 *       ---->  権限Xはボタンを利用できない。最悪の場合改修が漏れても、権限X以外の他のユーザに悪影響がない
 */
@Component({})
export class CurrentUserManager extends Vue {
  private get _userLoginType(): LoginType { return authModule.currentLoginType }

  get $isNormalLogin(): boolean { return this._userLoginType === LOGIN_TYPE.FROM_OWNER_APPS.NORMAL }
  get $isDummyLogin():boolean { return this._userLoginType === LOGIN_TYPE.FROM_OWNER_APPS.DUMMY }
  get $isSimulateLogin():boolean { return this._userLoginType === LOGIN_TYPE.FROM_ADMIN_APPS.SIMULATE }

  get $isAdmin(): boolean { return this.$isDummyLogin || this.$isSimulateLogin }
}
