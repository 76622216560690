import axios, { AxiosInstance, ResponseType } from 'axios'
import { plainToClass, ClassConstructor } from 'class-transformer'

import router from '@/router'
import { staticRoutes } from '@/routes'
import { join } from '@/libs/path'

import { errorsModule, structureModule, authModule, cognitoModule } from '@/stores'
import { LOGIN_TYPE } from '@/constants/constants'

const INNER_ERROR_STATUS = {
  VALIDATION: 400,
  BAD_REQUEST: 401,
  OPTIMISTIC_LOCK: 402,
  PERMISSION_DENIED: 403,
  NOT_FOUND: 404,
  SESSION_NOT_FOUND: 405,
  APPLICATION: 500,
  SERVICE_UNAVAILABLE: 503,
  NOT_PROCESSED: 600,

} as const

const ERROR_SHORT_MESSAGES = {
  VALIDATION: '入力エラーがあります',
  BAD_REQUEST: '操作や入力に誤りがあります',
  OPTIMISTIC_LOCK: '反映に失敗しました',
  PERMISSION_DENIED: 'その操作は受け付けられません',
  APPLICATION: 'システムエラーが発生しました',
  UNEXPECTED: 'システムエラーが発生しました',
} as const

const ACCESS_TOKEN = 'X-SM-ACCESS-TOKEN'

export abstract class APIClientBase {
  private readonly _client: AxiosInstance

  constructor(additionalBaseUrl?: string) {
    let AxiosRequestConfig = {}
    if (additionalBaseUrl) {
      AxiosRequestConfig = {
        baseURL: join(process.env.VUE_APP_API_BASE_URL, additionalBaseUrl),
        timeout: 10000, // 10sec.
        headers: { 'content-type': 'application/json' },
        withCredentials: true
      }
    } else {
      AxiosRequestConfig = {
        timeout: 10000, // 10sec.
      }
    }

    this._client = axios.create(AxiosRequestConfig)

    this._client.interceptors.request.use(config => {
      // アクセストークンをヘッダに設定（画面リロード等で消えないよう、session storageに保持したものを毎回設定）
      config.headers[ACCESS_TOKEN] = authModule.accessToken

      if (errorsModule.hasErrors) errorsModule.clearErrors()
      return config
    })

    this._client.interceptors.response.use(response => {
      return response
    },
    async error => {
      structureModule.forceHideProgressOverlay()
      if (error.response && error.response.data) { // smooth-eサーバからのエラー
        const innerStatus = error.response.data.innerStatusCode
        errorsModule.setErrors(error.response.data)

        switch (innerStatus) {
          case INNER_ERROR_STATUS.VALIDATION: structureModule.updateSnackbarErrorMessage(ERROR_SHORT_MESSAGES.VALIDATION); break
          case INNER_ERROR_STATUS.BAD_REQUEST: structureModule.updateSnackbarErrorMessage(ERROR_SHORT_MESSAGES.BAD_REQUEST); break
          case INNER_ERROR_STATUS.OPTIMISTIC_LOCK: structureModule.updateSnackbarErrorMessage(ERROR_SHORT_MESSAGES.OPTIMISTIC_LOCK); break
          case INNER_ERROR_STATUS.SESSION_NOT_FOUND:
            switch (authModule.currentLoginType) {
              case LOGIN_TYPE.FROM_OWNER_APPS.NORMAL: structureModule.openReLoginDialog(); break
              case LOGIN_TYPE.FROM_ADMIN_APPS.SIMULATE: structureModule.openSimulateReLoginDialog(); break
              case LOGIN_TYPE.FROM_OWNER_APPS.DUMMY: {
                errorsModule.clearErrors()
                try {
                  // セッションを再確立する
                  await cognitoModule.createSession()
                  // セッションキーを新たに確立したものに上書きした上で、再度同じリクエストを送る
                  error.config.headers[ACCESS_TOKEN] = authModule.accessToken
                  return await axios.request(error.config)
                } catch (error) {
                  // エラーが発生した場合は手動での再ログインを促す
                  structureModule.openReLoginDialog()
                  throw error
                }
              }
              default: {
                const _exhaustiveCheck: never = authModule.currentLoginType
                return _exhaustiveCheck
              }
            }
            break
          case INNER_ERROR_STATUS.PERMISSION_DENIED: structureModule.updateSnackbarErrorMessage(ERROR_SHORT_MESSAGES.PERMISSION_DENIED); break
          case INNER_ERROR_STATUS.NOT_FOUND: router.replace({ name: staticRoutes.notFound.name }); break
          case INNER_ERROR_STATUS.APPLICATION: structureModule.updateSnackbarErrorMessage(ERROR_SHORT_MESSAGES.APPLICATION); break
          case INNER_ERROR_STATUS.SERVICE_UNAVAILABLE: router.push({ name: staticRoutes.maintenance.name }); break
          case INNER_ERROR_STATUS.NOT_PROCESSED:
            // 管理者操作の場合はエラーを握りつぶす
            errorsModule.clearErrors()
            return
          default: structureModule.updateSnackbarErrorMessage(ERROR_SHORT_MESSAGES.UNEXPECTED); break
        }
      } else {
        structureModule.updateSnackbarErrorMessage(ERROR_SHORT_MESSAGES.UNEXPECTED)
        errorsModule.setGlobalErrors(['予期せぬエラーが発生しました。時間を置いてから再度実行するか、改善しない場合は管理者業務執行者までお問い合わせください。'])
      }
      throw error
    })
  }

  protected async get<T>(url: string, params: unknown, responseClass: ClassConstructor<T>, showProgressOverlay = true): Promise<T> {
    if (showProgressOverlay) structureModule.requestShowProgressOverlay()
    const fullResponse = await this._client.get(url, {
      params: this._formatQueryParams(params),
    })

    if (showProgressOverlay) structureModule.requestHideProgressOverlay()
    return plainToClass(responseClass, fullResponse.data)
  }

  protected async getPlain<T>(url: string, responseType?: ResponseType): Promise<T> {
    const fullResponse = await this._client.get(url, {
      responseType: responseType
    })

    return fullResponse.data
  }

  protected async post<T>(url: string, body: unknown, responseClass?: ClassConstructor<T>, showProgressOverlay = true): Promise<T | undefined> {
    if (showProgressOverlay) structureModule.requestShowProgressOverlay()
    const fullResponse = await this._client.post(url, body)

    if (showProgressOverlay) structureModule.requestHideProgressOverlay()

    if (!responseClass) return undefined
    return plainToClass(responseClass, fullResponse.data)
  }

  protected async put<T>(url: string, body: unknown, responseClass?: ClassConstructor<T>, showProgressOverlay = true): Promise<T | undefined> {
    if (showProgressOverlay) structureModule.requestShowProgressOverlay()
    const fullResponse = await this._client.put(url, body)

    if (showProgressOverlay) structureModule.requestHideProgressOverlay()

    if (!responseClass) return undefined
    return plainToClass(responseClass, fullResponse.data)
  }

  protected async delete<T>(url: string, responseClass?: ClassConstructor<T>, showProgressOverlay = true): Promise<T | undefined> {
    if (showProgressOverlay) structureModule.requestShowProgressOverlay()
    const fullResponse = await this._client.delete(url)

    if (showProgressOverlay) structureModule.requestHideProgressOverlay()

    if (!responseClass) return undefined
    return plainToClass(responseClass, fullResponse.data)
  }

  /**
   * クエリパラメータの項目値として配列が設定されていた場合に、項目名に配列番号を添字としてその要素を展開します。
   * e.g. { types: [11, 12, 13] } => { 'types[0]': 11, 'types[1]': 12, 'types[2]': 13, }
   * @param params クエリパラメータとして指定する、キーと値の組み合わせのオブジェクト
   */
  private _formatQueryParams(params: unknown) {
    if (typeof params !== 'object' || params === null) return params

    return Object.entries(params).reduce((acc: Record<string, unknown>, [key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v, idx) => { acc[`${key}[${idx}]`] = v })
      } else {
        acc[key] = value
      }
      return acc
    }, {})
  }
}
