<template>
  <svg
    class="sm__svg"
    height="24px"
    width="24px"
  >
    <path
      d="M18 4H6C5.44772 4 5 4.44772 5 5V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V5C19 4.44772 18.5523 4 18 4Z"
      stroke="currentColor"
      stroke-width="2"
      fill="white"
    />
    <path
      d="M10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13C9.55228 13 10 12.5523 10 12Z"
    />
    <path
      d="M10 16C10 15.4477 9.55228 15 9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17C9.55228 17 10 16.5523 10 16Z"
    />
    <path
      d="M15 11H12C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11Z"
    />
    <path
      d="M14 7H9C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7Z"
    />
    <path
      d="M15 15H12C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15Z"
    />
  </svg>
</template>
<style scoped>
.sm__svg {
     fill:currentColor;
}
</style>
