import { createStore } from 'vuex'
import { config, Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import createPersistedState from 'vuex-persistedstate'
import { PRESERVED_STATE_SESSION_PATHS, PRESERVED_STATE_LOCAL_PATHS } from './preserve-state/paths'

import { AUTH_STATE, DUMMY_AUTH_ERROR_MESSAGE, LOGIN_TYPE, REPAIR_PLAN_TABS } from '@/constants/constants'
import type { AuthState, LoginType } from '@/constants/constants'
import Auth, { CognitoUser } from '@aws-amplify/auth'
import { ClassConstructor, plainToClass } from 'class-transformer'
import { skipUpdate } from '@/libs/badge-skip-update'

import { authClient } from '@/clients/auth-client'
import { myCurrentBuildingClient } from '@/clients/my-current-building-client'
import { myBuildingsClient } from '@/clients/my-buildings-client'
import { resolutionsClient } from '@/clients/resolutions-client'
import { notificationsClient } from '@/clients/notifications-client'

import { Accountings, Availabilities, BuildingBasics, GetMyCurrentBuildingResponse, Requirements } from '@/dtos/my/building/current/get'
import { NotificationsGetRequest, NotificationsGetResponse, Notification } from '@/dtos/notifications/get'
import { NotificationDetailGetRequest, NotificationDetailGetResponse } from '@/dtos/notifications/get-detail'
import { NotificationsReadPostRequest } from '@/dtos/notifications/post'
import { ResolutionReadsPostRequest } from '@/dtos/resolutions/reads/post'
import { QuestionsGetRequest, Question, FILTER_TYPE, QuestionsGetResponse } from '@/dtos/resolutions/questions/get'
import { QuestionDetailGetRequest, QuestionDetailGetResponse } from '@/dtos/resolutions/questions/get-detail'
import { QuestionsPostRequest } from '@/dtos/resolutions/questions/post'
import { QuestionsPutRequest } from '@/dtos/resolutions/questions/put'
import { QuestionsDeleteRequest } from '@/dtos/resolutions/questions/delete'
import { NavigationItems } from '@/dtos/navigation'
import { MyBuildingsGetResponse } from '@/dtos/my/building/get'
import { LOGIN_RESULT_CODE, OwnerProfileDto } from '@/dtos/auth/common'
import { AuthAdminPostRequest, AuthAdminPostResponse } from '@/dtos/auth/admin/post'
import { LoginPostRequest, LoginPostResponse } from '@/dtos/auth/login/post'
import { IdentificationPostRequest, IdentificationPostResponse, IDENTIFY_RESULT_CODE } from '@/dtos/auth/identification/post'
import { AdditionalIdentificationPostRequest, AdditionalIdentificationPostResponse } from '@/dtos/auth/identification/additional/post'
import { SsoVerifyGetResponse } from '@/dtos/auth/sso-verify/get'
import { SsoVerifyPostRequest, SsoVerifyResultCode, SSO_VERIFY_RESULT_CODE } from '@/dtos/auth/sso-verify/post'
import { UserIcon } from '@/dtos/static-assets/user-icons/get'
import { LogoutPostResponse } from '@/dtos/auth/logout/post'
import { SimulateLoginPostRequest, SimulateLoginPostResponse } from '@/dtos/auth/simulate/post'
import { CurrentBuildingPostRequest } from '@/dtos/my/building/current/post'

import { NAVIGATION_GROUP } from '@/routes'
import type { NavigationGroup } from '@/routes'

config.rawError = true

const KEY = 'smooth-e'

// すでにstateが保持されていればそれを、でなければ初期化したものをストアとして使う
clearInvalidStorage()
export const isAlreadyInitialized = window.sessionStorage.getItem(KEY) !== null
export const isAlreadyLocalStored = window.localStorage.getItem(KEY) !== null

const persistedSessionStorage = createPersistedState({
  key: KEY,
  paths: PRESERVED_STATE_SESSION_PATHS,
  storage: window.sessionStorage,
})

const persistedLocalStorage = createPersistedState({
  key: KEY,
  paths: PRESERVED_STATE_LOCAL_PATHS,
  storage: window.localStorage,
})

export const store = createStore({
  modules: {
    /** dynamically injected if module declared as @Module({ store, dynamic: true }) */
  },
  plugins: [persistedSessionStorage, persistedLocalStorage]
})

function clearInvalidStorage() {
  try {
    const _v = window.sessionStorage.getItem(KEY)
    if (!_v) return
    else if (_v === '{}') {
      window.sessionStorage.removeItem(KEY)
      return
    }

    const v = JSON.parse(_v)
    for (const p of PRESERVED_STATE_SESSION_PATHS) {
      if (!v[p]) {
        window.sessionStorage.removeItem(KEY)
        return
      }
    }
  } catch {
    // parseに失敗した場合
    window.sessionStorage.removeItem(KEY)
  }

  try {
    const _v = window.localStorage.getItem(KEY)
    if (!_v) return
    else if (_v === '{}') {
      window.localStorage.removeItem(KEY)
      return
    }

    const v = JSON.parse(_v)
    for (const p of PRESERVED_STATE_LOCAL_PATHS) {
      if (!v[p]) {
        window.localStorage.removeItem(KEY)
        return
      }
    }
  } catch {
    // parseに失敗した場合
    window.localStorage.removeItem(KEY)
  }
}

@Module({ store, dynamic: true, namespaced: true, name: 'errors' })
class ErrorsStore extends VuexModule {
  private _fieldErrors: { [key: string]: string[] } = {}
  private _globalErrors: string[] = []

  get fieldErrors() {
    return (fieldId: string): string[] => {
      return this._fieldErrors[fieldId]
    }
  }

  get globalErrors(): string[] {
    return this._globalErrors
  }

  get hasErrors(): boolean {
    return Object.keys(this._fieldErrors).length > 0 || this._globalErrors.length > 0
  }

  @Mutation
  private SET_ERRORS(errors: { fieldErrors: { [key: string]: string[] }, globalErrors: string[] }): void {
    this._fieldErrors = errors.fieldErrors ?? {}
    this._globalErrors = errors.globalErrors ?? []
  }

  @Mutation
  private SET_GLOBAL_ERRORS(globalErrors: string[]): void {
    this._globalErrors = globalErrors
  }

  @Mutation
  private CLEAR_ERRORS(): void {
    this._fieldErrors = {}
    this._globalErrors = []
  }

  @Mutation
  private CLEAR_FIELD_ERRORS(fieldId: string): void {
    delete this._fieldErrors[fieldId]
  }

  @Action
  setErrors(errors: { fieldErrors: { [key: string]: string[] }, globalErrors: string[] }): void {
    this.SET_ERRORS(errors)
  }

  @Action
  setGlobalErrors(globalErrors: string[]): void {
    this.SET_GLOBAL_ERRORS(globalErrors)
  }

  @Action
  clearErrors(): void {
    this.CLEAR_ERRORS()
  }

  @Action
  clearFieldErrors(filedId: string): void {
    this.CLEAR_FIELD_ERRORS(filedId)
  }
}

export const errorsModule = getModule(ErrorsStore)

const statusBeforeLogin: AuthState[] = [AUTH_STATE.ANONYMOUS, AUTH_STATE.HAVE_TO_MFA, AUTH_STATE.HAVE_TO_CAUTION]

export class Credential {
  id?: string
  password?: string
}

export const CHALLENGE_NAMES = {
  PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  MFA: 'SMS_MFA'
} as const
export type ChallengeName = typeof CHALLENGE_NAMES[keyof typeof CHALLENGE_NAMES]

export class ChallengeParam {
  CODE_DELIVERY_DELIVERY_MEDIUM = ''
  CODE_DELIVERY_DESTINATION = ''
}

export type AuthUser = CognitoUser & {
  challengeName: ChallengeName,
  challengeParam: ChallengeParam
}

@Module({ store, dynamic: true, namespaced: true, name: 'cognito', preserveState: isAlreadyInitialized })
class CognitoStore extends VuexModule {
  private _user: AuthUser | null = null
  private _isAuthenticated = false
  private _credential = new Credential()

  get isNewPasswordRequired(): boolean {
    return this._user?.challengeName === CHALLENGE_NAMES.PASSWORD_REQUIRED ?? false
  }

  get isMFARequired(): boolean | undefined {
    return this._user?.challengeName === CHALLENGE_NAMES.MFA
  }

  get phoneNumber():string | undefined {
    return this._user?.challengeParam.CODE_DELIVERY_DESTINATION
  }

  get isAuthenticated():boolean {
    return this._isAuthenticated
  }

  @Mutation
  private SET_USER(user: AuthUser | null): void {
    this._user = user
  }

  @Mutation
  private SET_IS_AUTHENTICATED(val:boolean) {
    this._isAuthenticated = val
  }

  @Mutation
  private SET_CREDENTIAL(credential: Credential) {
    this._credential = credential
  }

  @Action
  async signIn(credential: Credential) {
    await this.cognitoSignIn(credential)
    this.SET_CREDENTIAL(credential)
  }

  @Action
  async cognitoSignIn(credential: Credential) {
    if (!credential.id || !credential.password) return
    const user = await Auth.signIn(credential.id, credential.password)
    authModule.setStateHaveToMfa()
    this.SET_USER(user)
  }

  @Action
  async confirmSignIn(code:string) {
    await Auth.confirmSignIn(this._user, code, CHALLENGE_NAMES.MFA)
  }

  @Action
  async createSession() {
    this.SET_IS_AUTHENTICATED(false)
    try {
      const session = await Auth.currentSession()
      await authModule.postAdminLogin(session.getAccessToken().getJwtToken())
    } catch (err) {
      // セッション確立失敗した場合はログアウト
      await this.signOut()
      throw err
    }

    this.SET_IS_AUTHENTICATED(true)
  }

  @Action
  deleteCredential() {
    this.SET_CREDENTIAL(new Credential())
  }

  @Action
  async signOut() {
    // smooth-e側ログアウトがまだされていなければログアウト
    if (!statusBeforeLogin.includes(authModule.currentAuthState)) {
      await authModule.postLogout()
    }

    // cognito側ログアウト
    try {
      await Auth.signOut()
    } catch {
      // 最初からcognito側にログインしてなかった場合はエラーが出るが、特に問題はないため握りつぶす
    }

    this.SET_IS_AUTHENTICATED(false)
    this.SET_USER(null)
  }

  @Action
  async completeNewPassword(password: string): Promise<void> {
    const user = await Auth.completeNewPassword(this._user, password)
    this.SET_USER(user)
    const credential = this._credential
    credential.password = password
    this.SET_CREDENTIAL(credential)
  }

  @Action
  async resendingSMS() {
    await this.cognitoSignIn(this._credential)
  }

  @Action
  async changePassword(password: {currentPassword: string, newPassword: string}): Promise<void> {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, password.currentPassword, password.newPassword)
  }
}

export const cognitoModule = getModule(CognitoStore)

@Module({ store, dynamic: true, namespaced: true, name: 'auth', preserveState: isAlreadyInitialized })
class AuthStore extends VuexModule {
  // ログイン認証前の特定の画面へアクセスしてきた際に指定されることのある、ログイン後に遷移したい画面の情報（メールやプッシュ通知、素敵ネットからの遷移）
  // FLPとのリダイレクトのやり取りの中で初期に指定された値を保持し続けられるようにしている
  private _memorizedQueryParams: Record<string, string> = {}
  get memorizedQueryParams(): Record<string, string> { return this._memorizedQueryParams }

  @Mutation
  private SET_QUERY_PARAMS(params?: Record<string, string>): void { this._memorizedQueryParams = params ?? {} }

  @Action public memorizeQueryParams(params: Record<string, string>) { this.SET_QUERY_PARAMS(params) }
  @Action public clearQueryParams() { this.SET_QUERY_PARAMS(undefined) }

  // -------------------- 認証系APIで共通保持する項目 --------------------

  // smooth-eのAPIを利用するためのアクセストークン（期限付き）
  private _accessToken = ''
  get accessToken(): string { return this._accessToken }
  @Mutation private SET_ACCESS_TOKEN(token: string): void { this._accessToken = token }

  // ログインや初回動線の進行具合によって移り変わる認証状態を保持する。主に、未認証で利用できない画面への遷移を防止するのに参照する。
  private _authState: AuthState = AUTH_STATE.ANONYMOUS
  get currentAuthState(): AuthState { return this._authState }
  @Mutation private SET_AUTH_STATE(state: AuthState): void { this._authState = state }

  // ログイン種別
  private _loginType: LoginType = LOGIN_TYPE.FROM_OWNER_APPS.NORMAL
  get currentLoginType(): LoginType { return this._loginType }
  @Mutation SET_LOGIN_TYPE(type: LoginType): void { this._loginType = type }

  // ログインユーザのプロフィール
  private _profile = new OwnerProfileDto()
  get profile(): OwnerProfileDto { return this._profile }
  @Mutation private SET_PROFILE(profile: OwnerProfileDto): void { this._profile = profile }

  // ----------------------------------------

  private _loginResponse: Omit<LoginPostResponse, 'profile' | 'authState'> = new LoginPostResponse()
  get loginResponse(): Omit<LoginPostResponse, 'profile' | 'authState'> { return this._loginResponse }

  @Mutation
  private SET_LOGIN_RESPONSE(res: Omit<LoginPostResponse, 'profile' | 'authState'>):void {
    this._loginResponse = res
  }

  @Action
  public async postLogin(req: LoginPostRequest): Promise<void> {
    const res = await authClient.postLogin(req)
    if (!res) return

    if (res.resultCode === LOGIN_RESULT_CODE.SUCCESS) {
      // SUCCESSなら必ずある想定
      if (res.authState) this.SET_AUTH_STATE(res.authState)
      if (res.profile) this.SET_PROFILE(res.profile)
      if (res.accessToken) this.SET_ACCESS_TOKEN(res.accessToken)
    }
    delete res.authState
    delete res.profile
    this.SET_LOGIN_RESPONSE(res)
    this.SET_LOGIN_TYPE(LOGIN_TYPE.FROM_OWNER_APPS.NORMAL)
  }

  // ----------------------------------------

  private _identificationResponse: Omit<IdentificationPostResponse, 'profile'> = new IdentificationPostResponse()

  get identificationResponse(): Omit<IdentificationPostResponse, 'profile'> {
    return this._identificationResponse
  }

  @Mutation
  private SET_IDENTIFICATION_RESPONSE(res: Omit<IdentificationPostResponse, 'profile'>):void {
    this._identificationResponse = res
  }

  @Action
  public async postIdentification(req: IdentificationPostRequest): Promise<void> {
    const res = await authClient.postIdentification(req)
    if (!res) return

    if (res.resultCode === IDENTIFY_RESULT_CODE.SUCCESS && !res.requiredAdditionalIdentification) {
      this.SET_AUTH_STATE(AUTH_STATE.HAVE_TO_INITIALIZE)
      // SUCCESSなら必ずある想定
      if (res.profile) this.SET_PROFILE(res.profile)
      if (res.accessToken) this.SET_ACCESS_TOKEN(res.accessToken)
    }
    delete res.profile
    this.SET_IDENTIFICATION_RESPONSE(res)
  }

  private _additionalIdentificationResponse: Omit<AdditionalIdentificationPostResponse, 'profile'> = new AdditionalIdentificationPostResponse()

  get additionalIdentificationResponse(): Omit<AdditionalIdentificationPostResponse, 'profile'> {
    return this._additionalIdentificationResponse
  }

  @Mutation
  private SET_ADDITIONAL_IDENTIFICATION_RESPONSE(res: Omit<AdditionalIdentificationPostResponse, 'profile'>):void {
    this._additionalIdentificationResponse = res
  }

  @Action
  public async postAdditionalIdentification(req: AdditionalIdentificationPostRequest): Promise<void> {
    const res = await authClient.postAdditionalIdentification(req)
    if (!res) return

    if (res.resultCode === IDENTIFY_RESULT_CODE.SUCCESS) {
      this.SET_AUTH_STATE(AUTH_STATE.HAVE_TO_INITIALIZE)
      // SUCCESSなら必ずある想定
      if (res.profile) this.SET_PROFILE(res.profile)
      if (res.accessToken) this.SET_ACCESS_TOKEN(res.accessToken)
    }
    delete res.profile
    this.SET_ADDITIONAL_IDENTIFICATION_RESPONSE(res)
  }

  @Action
  public clearIdentification():void {
    this.SET_IDENTIFICATION_RESPONSE(new IdentificationPostResponse())
  }

  @Action
  public clearAdditionalIdentification():void {
    this.SET_ADDITIONAL_IDENTIFICATION_RESPONSE(new AdditionalIdentificationPostResponse())
  }

  // ------物件選択-----------------
  @Action
  public markAsSelectedBuilding(): void { this.SET_AUTH_STATE(AUTH_STATE.AUTHORIZED) }

  // ----------------------------------------
  private _ssoVerifyPreRequest: SsoVerifyGetResponse | null = null
  get ssoVerifyPreRequest() : SsoVerifyGetResponse | null { return this._ssoVerifyPreRequest }
  @Mutation private SET_SSO_VERIFY_GET(res?: SsoVerifyGetResponse) { this._ssoVerifyPreRequest = res ?? null }
  @Action public async getSsoVerify() { this.SET_SSO_VERIFY_GET(await authClient.getSsoVerify()) }

  private _ssoVerifyResult: SsoVerifyResultCode | null = null
  get ssoVerifyResult(): SsoVerifyResultCode | null { return this._ssoVerifyResult }
  @Mutation private SET_SSO_VERIFY_RESULT(code: SsoVerifyResultCode | null) { this._ssoVerifyResult = code }

  @Action
  public async postSsoVerify(refreshToken: string): Promise<void> {
    const res = await authClient.postSsoVerify(new SsoVerifyPostRequest(refreshToken))
    if (!res) return

    this.SET_SSO_VERIFY_RESULT(res.resultCode)
    if (res.resultCode === SSO_VERIFY_RESULT_CODE.SUCCESS) {
      // SUCCESSなら必ずある想定
      if (res.authState) this.SET_AUTH_STATE(res.authState)
      if (res.profile) this.SET_PROFILE(res.profile)
      if (res.accessToken) this.SET_ACCESS_TOKEN(res.accessToken)
    }
  }

  // ----------------------------------------

  private _logoutResponse: LogoutPostResponse | null = null
  get logoutRedirect(): string | undefined { return this._logoutResponse?.redirectTo }
  @Mutation private SET_LOGOUT_RESPONSE(res: LogoutPostResponse) { this._logoutResponse = res }

  @Action
  public async postLogout(): Promise<void> {
    // サーバ側でのログアウト処理の成否に関わらず、クライアント側の制御を完了したら終了とする
    try {
      const res = await authClient.postLogout()
      if (res) this.SET_LOGOUT_RESPONSE(res)
    } catch { /* kill error */ } finally {
      // ログインしていないと保持しえない情報をリセット（※ログイン種別はログアウト直後の画面遷移先判定に用いるためここではリセットせず、ログイン時に更新する）
      this.SET_AUTH_STATE(AUTH_STATE.ANONYMOUS)
      this.SET_ACCESS_TOKEN('')
    }
  }

  @Action
  public markAsInitialized(): void {
    this.SET_AUTH_STATE(AUTH_STATE.AUTHORIZED)
  }

  @Action
  public changeIcon(icon:UserIcon):void {
    const profile = plainToClass(OwnerProfileDto, this._profile)
    profile.icon = icon
    this.SET_PROFILE(profile)
  }

  // ------------- LMログイン用 -------------
  @Action
  public async postAdminLogin(accessToken:string) {
    const req = new AuthAdminPostRequest(accessToken, myCurrentBuildingModule.basics.buildingId)
    let res : AuthAdminPostResponse | undefined
    try {
      res = await authClient.postAdminLogin(req)
    } catch {
      throw new Error(DUMMY_AUTH_ERROR_MESSAGE.UNEXPECTED_ERROR)
    }

    // 認証失敗したら例外を投げる（cognito側からログアウト処理を走らせるため）
    if (!res || res.resultCode !== LOGIN_RESULT_CODE.SUCCESS || !res.profile || !res.accessToken) {
      throw new Error(DUMMY_AUTH_ERROR_MESSAGE.USER_DISABLED)
    }
    this.SET_ACCESS_TOKEN(res.accessToken)

    await myBuildingsModule.fetchMyBuildings()

    // 現在設定している物件が選択不能になった場合、セッション切れ扱いにする
    if (myCurrentBuildingModule.basics.buildingId && !myBuildingsModule.myBuildingsGet.buildings.some(b => b.buildingId === myCurrentBuildingModule.basics.buildingId)) {
      structureModule.openReLoginDialog()
      return
    }

    // 物件0件なら紐付く物件なし→認証失敗扱いでエラー
    if (myBuildingsModule.myBuildingsGet.buildings.length === 0) {
      throw new Error(DUMMY_AUTH_ERROR_MESSAGE.NO_BUILDING_ASSIGNED)
    }

    this.SET_PROFILE(res.profile)
    this.SET_LOGIN_TYPE(LOGIN_TYPE.FROM_OWNER_APPS.DUMMY)
  }

  @Action
  public setStateAnonymous(): void {
    this.SET_AUTH_STATE(AUTH_STATE.ANONYMOUS)
  }

  @Action
  public setStateHaveToMfa(): void {
    this.SET_AUTH_STATE(AUTH_STATE.HAVE_TO_MFA)
  }

  @Action
  public setStateHaveToCaution(): void { this.SET_AUTH_STATE(AUTH_STATE.HAVE_TO_CAUTION) }

  @Action
  public setStateSelectBuilding(): void { this.SET_AUTH_STATE(AUTH_STATE.HAVE_TO_SELECT_BUILDING) }

  @Action
  public setStateLMAuthorized(): void { this.SET_AUTH_STATE(AUTH_STATE.AUTHORIZED) }

  // ------------- 成り代わりログイン用 -------------
  private _simulateLoginResponse: Omit<SimulateLoginPostResponse, 'profile'> = new SimulateLoginPostResponse()
  get simulateLoginResponse(): Omit<SimulateLoginPostResponse, 'profile'> { return this._simulateLoginResponse }

  @Mutation
  private SET_SIMULATE_LOGIN_RESPONSE(res: Omit<SimulateLoginPostResponse, 'profile'>):void {
    this._simulateLoginResponse = res
  }

  @Action
  public async postSimulateLogin(req: SimulateLoginPostRequest): Promise<void> {
    const res = await authClient.postSimulateLogin(req)
    if (!res) return

    if (res.resultCode === LOGIN_RESULT_CODE.SUCCESS) {
      // SUCCESSなら必ずある想定
      if (res.profile) this.SET_PROFILE(res.profile)
      if (res.accessToken) this.SET_ACCESS_TOKEN(res.accessToken)

      this.SET_AUTH_STATE(AUTH_STATE.AUTHORIZED)
      this.SET_LOGIN_TYPE(LOGIN_TYPE.FROM_ADMIN_APPS.SIMULATE)
    }
    delete res.profile
    this.SET_SIMULATE_LOGIN_RESPONSE(res)
  }
}

export const authModule = getModule(AuthStore)

@Module({ store, dynamic: true, namespaced: true, name: 'analytics', preserveState: isAlreadyInitialized })
class AnalyticsStore extends VuexModule {
  // 通知自体のID
  private _notificationId:string | null = null
  public get notificationId():string | null { return this._notificationId }
  @Mutation private SET_NOTIFICATION_ID(notificationId:string | null) { this._notificationId = notificationId }
  @Action public setNotificationId(notificationId:string) { this.SET_NOTIFICATION_ID(notificationId) }

  // 通知を受けたユーザーID。メール・プッシュ通知に付与される。
  private _notifiedUserId:string | null = null
  public get notifiedUserId():string | null { return this._notifiedUserId }
  @Mutation private SET_NOTIFIED_USER_ID(notifiedUserId:string | null) { this._notifiedUserId = notifiedUserId }
  @Action public setNotifiedUserId(notifiedUserId:string) { this.SET_NOTIFIED_USER_ID(notifiedUserId) }

  private _notificationType:string | null = null
  public get notificationType():string | null { return this._notificationType }
  @Mutation private SET_NOTIFICATION_TYPE(notificationType:string | null) { this._notificationType = notificationType }
  @Action public setNotificationType(notificationType:string) { this.SET_NOTIFICATION_TYPE(notificationType) }

  private _ownerNotificationId:string | null = null
  public get ownerNotificationId():string | null { return this._ownerNotificationId }
  @Mutation private SET_OWNER_NOTIFICATION_ID(ownerNotificationId:string | null) { this._ownerNotificationId = ownerNotificationId }
  @Action public setOwnerNotificationId(ownerNotificationId:string) { this.SET_OWNER_NOTIFICATION_ID(ownerNotificationId) }

  @Action public clearAnalyticsProperties() {
    this.SET_NOTIFICATION_ID(null)
    this.SET_NOTIFIED_USER_ID(null)
    this.SET_NOTIFICATION_TYPE(null)
    this.SET_OWNER_NOTIFICATION_ID(null)
  }
}

export const analyticsModule = getModule(AnalyticsStore)

const format = (d: Date): string => { return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}` }

@Module({ store, dynamic: true, namespaced: true, name: 'myCurrentBuilding', preserveState: isAlreadyInitialized })
class MyCurrentBuildingStore extends VuexModule {
  private _myCurrentBuilding = new GetMyCurrentBuildingResponse()

  public get basics(): BuildingBasics { return this._myCurrentBuilding.basics }
  public get requirements(): Requirements { return this._myCurrentBuilding.requirements }
  public get accountings(): Accountings { return this._myCurrentBuilding.accountings }
  public get availabilities(): Availabilities { return this._myCurrentBuilding.availabilities }

  public get currentPeriod(): number {
    const now = new Date()
    const base = now.getFullYear() - this.accountings.firstPeriodEndYear + 1

    return now.getTime() > this._periodEndDate(base).getTime() ? base + 1 : base
  }

  /**
   * 期を決算の年月に変換する関数を返します
   * 例えば、1期の会計年度が2021年2月～2022年1月であれば、「1 -> 2022/1」「5 -> 2026/1」です
   */
  public get periodToYearMonth() {
    return (period: number) => `${this._periodToEndYear(period)}/${this.accountings.accountingMonth}`
  }

  /**
   * 期を会計年度の開始・終了日に変換する関数を返します
   */
  public get periodToSpan() {
    return (period: number): { from: string, to: string } =>
      ({ from: format(this._periodStartDate(period)), to: format(this._periodEndDate(period)) })
  }

  private get _periodToEndYear() { return (period: number) => this.accountings.firstPeriodEndYear + period - 1 }

  // 開始日は「選択年の前年、決算月の翌月、1日」、終了日は「選択年、決算月、最終日」  ※Dateコンストラクタの第2引数はindex（1月が0）
  // なお、Dateコンストラクタは日に0を指定すると「1つ前の月の最終日」を指す
  private get _periodStartDate() { return (period: number) => { return new Date(this._periodToEndYear(period) - 1, this.accountings.accountingMonth, 1) } }
  private get _periodEndDate() { return (period: number) => { return new Date(this._periodToEndYear(period), this.accountings.accountingMonth, 0, 23, 59, 59) } }

  @Mutation
  private SET_MY_CURRENT_BUILDING(res:GetMyCurrentBuildingResponse): void {
    this._myCurrentBuilding = res
  }

  @Action
  public async fetchMyCurrentBuilding(): Promise<void> {
    const res = await myCurrentBuildingClient.getCurrentBuilding()
    this.SET_MY_CURRENT_BUILDING(res)
  }

  @Action
  public async postCurrentBuilding(buildingId:string): Promise<void> {
    await myCurrentBuildingClient.postCurrentBuilding(new CurrentBuildingPostRequest(buildingId))
  }
}

export const myCurrentBuildingModule = getModule(MyCurrentBuildingStore)

@Module({ store, dynamic: true, namespaced: true, name: 'notifications' })
class NotificationsStore extends VuexModule {
  private _notifications: Notification[] = []

  private _notificationBadgeLastUpdateTime:Date = new Date(0)

  get notifications(): Notification[] {
    return this._notifications
  }

  @Mutation
  private PUSH_NOTIFICATIONS(res: NotificationsGetResponse): void {
    this._notifications.push(...res.notifications)
    this._notificationBadgeLastUpdateTime = new Date()
  }

  @Mutation
  private CLEAR_NOTIFICATIONS(): void {
    this._notifications = []
  }

  @Action
  async fetchNotifications(req: NotificationsGetRequest): Promise<void> {
    const res = await notificationsClient.getNotifications(req)
    this.PUSH_NOTIFICATIONS(res)
    this.SET_UNREAD_COUNT(res.unreadCount)
  }

  @Action
  clearFetchedNotifications(): void {
    this.CLEAR_NOTIFICATIONS()
  }

  // 既読登録
  @Action
  async postNotificationsRead(req?: NotificationsReadPostRequest): Promise<void> {
    await notificationsClient.postNotificationsRead(req)
  }

  private _unreadCount = 0

  get unreadCount():number { return this._unreadCount }

  @Mutation
  private SET_UNREAD_COUNT(res:number):void {
    this._unreadCount = res
  }

  public get notificationBadgeVisible():boolean {
    return this._unreadCount > 0
  }

  // お知らせ通知バッジ更新
  @Action
  public async fetchNotificationBadgeVisible():Promise<void> {
    if (!skipUpdate(this._notificationBadgeLastUpdateTime)) {
      const res = await notificationsClient.getNotifications({ skip: 0, take: 1 })
      this.CLEAR_NOTIFICATIONS()
      this.PUSH_NOTIFICATIONS(res)
      this.SET_UNREAD_COUNT(res.unreadCount)
    }
  }

  /**
   * お知らせ通知バッジ更新
   * skipUpdateでバッジ更新のスキップ時間を考慮せず即時更新する
   */
  @Action
  public async fetchNotificationBadgeVisibleNow():Promise<void> {
    const res = await notificationsClient.getNotifications({ skip: 0, take: 1 })
    this.CLEAR_NOTIFICATIONS()
    this.PUSH_NOTIFICATIONS(res)
    this.SET_UNREAD_COUNT(res.unreadCount)
  }

  // お知らせ詳細
  private _notificationDetail: NotificationDetailGetResponse = new NotificationDetailGetResponse()

  get notificationDetail(): NotificationDetailGetResponse {
    return this._notificationDetail
  }

  @Mutation
  private SET_NOTIFICATION_DETAIL(res:NotificationDetailGetResponse) {
    this._notificationDetail = res
  }

  @Action
  async fetchNotificationDetail(req: NotificationDetailGetRequest) {
    const res = await notificationsClient.getNotificationDetail(req)
    this.SET_NOTIFICATION_DETAIL(res)
  }
}

export const notificationsModule = getModule(NotificationsStore)

/**
 * 再びその画面に戻ってくる場合を見越してパラメータを保持させたい場合などに、その保管先として用いるストア
 * 原則的に保持のキーは画面名（staticRoutes.hoge.name）を指定する想定だが、1画面に複数の保管対象がある場合などは、各画面内で一意になるよう定義する
 *
 * 保存したパラメータは、保存者の意図しない特定のタイミングでリセットされることがある（ログアウトや特別なボタン押下時など）
 * そういったリセットの影響を受けたくないパラメータは、キーの頭文字を小文字アンダースコア(_)で定義すること
 *
 * リアクティビティが要求されるユースケースは想定していない
 */
@Module({ store, dynamic: true, namespaced: true, name: 'paramStorage' })
class ParamStorageStore extends VuexModule {
  private _savedParams: Record<string, unknown> = {}

  get savedParam() {
    return <T>(key: string, parsedTo: ClassConstructor<T>): T | undefined => {
      if (!this._savedParams) return undefined
      const stored = this._savedParams[key]
      return stored ? plainToClass(parsedTo, stored) : undefined
    }
  }

  @Mutation private SET({ key, params }: { key: string, params: unknown }) {
    if (!this._savedParams) this._savedParams = {}
    this._savedParams[key] = params
  }

  @Mutation private DELETE(key: string) { delete this._savedParams[key] }

  @Action save(_: { key: string, params: unknown }) { this.SET(_) }
  @Action delete(key: string) { this.DELETE(key) }

  @Action deleteAll() {
    Object.keys(this._savedParams).filter(key => !key.startsWith('_')).forEach(key => this.DELETE(key))
  }
}

export const paramStorageModule = getModule(ParamStorageStore)

@Module({ store, dynamic: true, namespaced: true, name: 'resolutions' })
class ResolutionsStore extends VuexModule {
  // 質問
  @Action
  async postQuestions(req: QuestionsPostRequest): Promise<void> {
    await resolutionsClient.postQuestions(req)
  }

  @Action
  async putQuestions(req: QuestionsPutRequest): Promise<void> {
    await resolutionsClient.putQuestions(req)
  }

  @Action
  async deleteQuestions(req: QuestionsDeleteRequest): Promise<void> {
    await resolutionsClient.deleteQuestions(req)
  }

  // 質問詳細
  private _questionDetailGet: QuestionDetailGetResponse | null = null

  get questionDetailGet(): QuestionDetailGetResponse | null {
    return this._questionDetailGet
  }

  @Mutation
  private SET_QUESTION_DETAIL(res: QuestionDetailGetResponse | null) {
    this._questionDetailGet = res
  }

  @Action
  async fetchQuestionDetail(req: QuestionDetailGetRequest): Promise<void> {
    const res = await resolutionsClient.getQuestionDetail(req)
    this.SET_QUESTION_DETAIL(res)
  }

  @Action
  clearQuestionDetail(): void {
    this.SET_QUESTION_DETAIL(null)
  }

  // 既読
  @Action
  async postResolutionReads(req: ResolutionReadsPostRequest) {
    await resolutionsClient.postResolutionReads(req)
  }

  // 質問一覧
  private _myQuestionsInfo = new QuestionsGetResponse()
  private _myQuestions: Question[] = []
  private _othersQuestions: Question[] = []

  get myQuestionsInfo(): QuestionsGetResponse {
    return this._myQuestionsInfo
  }

  get myQuestions(): Question[] {
    return this._myQuestions
  }

  get othersQuestions(): Question[] {
    return this._othersQuestions
  }

  @Mutation
  private SET_MY_QUESTIONS_INFO(res: QuestionsGetResponse): void {
    this._myQuestionsInfo = res
  }

  @Mutation
  private SET_MY_QUESTIONS(questions: Question[]): void {
    this._myQuestions = questions
  }

  @Mutation
  private SET_OTHERS_QUESTIONS(questions: Question[]): void {
    this._othersQuestions = questions
  }

  @Mutation
  private PUSH_OTHERS_QUESTIONS(questions: Question[]): void {
    this._othersQuestions.push(...questions)
  }

  @Action
  async fetchThenOverwriteMyQuestions(req: QuestionsGetRequest): Promise<void> {
    if (req.filterType !== FILTER_TYPE.MY_QUESTIONS) return

    const res = await resolutionsClient.getQuestions(req)
    this.SET_MY_QUESTIONS_INFO(res)
    this.SET_MY_QUESTIONS(res.questions)
  }

  @Action
  async fetchOthersQuestions(req: QuestionsGetRequest): Promise<void> {
    if (req.filterType !== FILTER_TYPE.OTHERS_QUESTIONS) return

    const res = await resolutionsClient.getQuestions(req)
    this.PUSH_OTHERS_QUESTIONS(res.questions)
  }

  @Action
  clearOthersQuestions(): void {
    this.SET_OTHERS_QUESTIONS([])
  }

  private _unprocessedResolution:{
    resolutionIds:string[]
    lastUpdate:Date
  } = {
    resolutionIds: [],
    lastUpdate: new Date(0)
  }

  public get unprocessedResolutionBadgeVisible():boolean {
    return this._unprocessedResolution.resolutionIds.length > 0
  }

  // 未対応決議更新
  @Mutation
  private SET_UNPROCESSED_RESOLUTIONS(resolutionIds:string[]) {
    this._unprocessedResolution = {
      resolutionIds: resolutionIds,
      lastUpdate: new Date()
    }
  }

  @Action
  public async fetchUnprocessedResolutions(): Promise<void> {
    if (!skipUpdate(this._unprocessedResolution.lastUpdate)) {
      const res = await resolutionsClient.getUnprocessedResolutionsSummary()
      this.SET_UNPROCESSED_RESOLUTIONS(res.resolutionIds)
    }
  }

  @Action
  public async fetchUnprocessedResolutionsNow(): Promise<void> {
    const res = await resolutionsClient.getUnprocessedResolutionsSummary()
    this.SET_UNPROCESSED_RESOLUTIONS(res.resolutionIds)
  }

  // 対応した決議を未対応決議から削除
  @Action
  public deleteProcessedResolution(resolutionId:string):void {
    const resolutionIds = this._unprocessedResolution.resolutionIds.filter(e => e !== resolutionId)
    this.SET_UNPROCESSED_RESOLUTIONS(resolutionIds)
  }
}

export const resolutionsModule = getModule(ResolutionsStore)

export class AppBar {
  visible = true
  rightIconVisible = true
  title?: string
  leftIcon?: string
  isExtensionVisible = false
  selectedTab = REPAIR_PLAN_TABS.summary.tab
  constructor(init?: Partial<AppBar>) { Object.assign(this, init) }
}

export class SnackBar {
  message?: string
  display = false
  caution?: boolean
}

/**
 * ナビゲーション項目
 */
export const NAVIGATIONS:NavigationItems = { // ※ オブジェクトのキーとidの値は一致していなければならない
  decisionProcesses: { id: 'decisionProcesses', name: 'アイデア投稿・投票', icon: '$emoji_objects_outlined', badgeVisible: false },
  menu: { id: 'menu', name: 'メニュー', icon: '$dehaze_round', badgeVisible: false },
} as const
export type NavigationId = keyof typeof NAVIGATIONS | 'NOT_SELECTED'

/**
 * 共通のページ構造に関する値を保持するモジュール
 */
@Module({ store, dynamic: true, namespaced: true, name: 'structure' })
class StructureStore extends VuexModule {
  private _appBar = new AppBar()
  private _systemBarVisible = true
  private _navigationVisible = true
  private _currentNavigation: NavigationId = 'NOT_SELECTED'
  private _snackbar = new SnackBar()

  private _reLoginDialogVisible = false
  private _simulateReLoginDialogVisible = false

  private _specifiedVAppCss = ''
  private _applyBottomMargin = true
  private _overlayStacks = 0
  private _isPrintView = false
  private _globalErrorVisible = true

  get appBar(): AppBar { return this._appBar }
  get systemBarVisible(): boolean { return this._systemBarVisible }
  get navigationVisible(): boolean { return this._navigationVisible }
  get currentNavigation(): NavigationId { return this._currentNavigation }
  get snackbar(): SnackBar { return this._snackbar }

  get reLoginDialogVisible(): boolean { return this._reLoginDialogVisible }
  get simulateReLoginDialogVisible(): boolean { return this._simulateReLoginDialogVisible }

  get specifiedVAppCss(): string { return this._specifiedVAppCss }
  get applyBottomMargin(): boolean { return this._applyBottomMargin }
  get showOverlay(): boolean { return this._overlayStacks > 0 }
  get isPrintView():boolean { return this._isPrintView }
  get globalErrorVisible(): boolean { return this._globalErrorVisible }

  @Mutation
  private SET_SPECIFIED_VAPP_CSS(css: string): void { this._specifiedVAppCss = css }

  @Mutation
  private SET_CURRENT_NAVIGATION(id: NavigationId): void { this._currentNavigation = id }

  @Mutation
  private SET_APP_BAR(appBar: AppBar): void { this._appBar = appBar }

  @Mutation
  private SET_SYSTEM_BAR_VISIBLE(visible: boolean):void { this._systemBarVisible = visible }

  @Mutation
  private SET_NAVIGATION_VISIBLE(navigation: boolean):void { this._navigationVisible = navigation }

  @Mutation
  private SET_RE_LOGIN_DIALOG_VISIBLE(visible: boolean): void { this._reLoginDialogVisible = visible }

  @Mutation
  private SET_SIMULATE_RE_LOGIN_DIALOG_VISIBLE(visible: boolean): void { this._simulateReLoginDialogVisible = visible }

  @Mutation
  private SET_MARGIN_BOTTOM_VISIBLE(visible: boolean):void { this._applyBottomMargin = visible }

  @Mutation
  private ADD_OVERLAY_STACK(diff: number): void { this._overlayStacks = Math.max(this._overlayStacks + diff, 0) /* 最小値をゼロに */ }

  @Mutation
  private SET_SNACKBAR(snackbar: SnackBar): void { this._snackbar = snackbar }

  @Mutation
  private SET_IS_PRINT_VIEW(isPrintView: boolean): void { this._isPrintView = isPrintView }

  @Mutation
  private SET_GLOBAL_ERROR_VISIBLE(globalErrorVisible: boolean): void { this._globalErrorVisible = globalErrorVisible }

  @Action
  public specifyVAppCss(css: string): void {
    this.SET_SPECIFIED_VAPP_CSS(css)
  }

  @Action
  public changeCurrentNavigation(groupOfRoutingTo: NavigationGroup): void {
    switch (groupOfRoutingTo) {
      case NAVIGATION_GROUP.DECISION_PROCESS: this.SET_CURRENT_NAVIGATION(NAVIGATIONS.decisionProcesses.id); break
      case NAVIGATION_GROUP.MENU: this.SET_CURRENT_NAVIGATION(NAVIGATIONS.menu.id); break
      case NAVIGATION_GROUP.INDEPENDENT: this.SET_CURRENT_NAVIGATION('NOT_SELECTED'); break
      default: {
        const _exhaustiveCheck: never = groupOfRoutingTo
        return _exhaustiveCheck
      }
    }
  }

  @Action
  public updateAppBar(appBar: Partial<AppBar>): void {
    this.SET_APP_BAR(new AppBar(appBar))
  }

  @Action
  public deleteAppBar(): void {
    this.SET_APP_BAR(new AppBar({ visible: false }))
  }

  @Action
  public showSystemBar():void {
    this.SET_SYSTEM_BAR_VISIBLE(true)
  }

  @Action
  public hideSystemBar():void {
    this.SET_SYSTEM_BAR_VISIBLE(false)
  }

  @Action
  public showNavigation():void {
    this.SET_NAVIGATION_VISIBLE(true)
  }

  @Action
  public hideNavigation(): void {
    this.SET_NAVIGATION_VISIBLE(false)
  }

  @Action openReLoginDialog(): void { this.SET_RE_LOGIN_DIALOG_VISIBLE(true) }
  @Action closeReLoginDialog(): void { this.SET_RE_LOGIN_DIALOG_VISIBLE(false) }

  @Action openSimulateReLoginDialog(): void { this.SET_SIMULATE_RE_LOGIN_DIALOG_VISIBLE(true) }
  @Action closeSimulateReLoginDialog(): void { this.SET_SIMULATE_RE_LOGIN_DIALOG_VISIBLE(false) }

  @Action
  public showMarginBottom(): void {
    this.SET_MARGIN_BOTTOM_VISIBLE(true)
  }

  @Action
  public hideMarginBottom(): void {
    this.SET_MARGIN_BOTTOM_VISIBLE(false)
  }

  @Action public requestShowProgressOverlay(): void { this.ADD_OVERLAY_STACK(1) }
  @Action public requestHideProgressOverlay(): void { this.ADD_OVERLAY_STACK(-1) }
  @Action public forceHideProgressOverlay(): void { this.ADD_OVERLAY_STACK(-1 * this._overlayStacks) }

  @Action
  public updateSnackbarMessage(message: string) : void {
    this.SET_SNACKBAR({ message: message, display: true, caution: false })
  }

  @Action
  public updateSnackbarErrorMessage(message: string) : void {
    this.SET_SNACKBAR({ message: message, display: true, caution: true })
  }

  @Action
  public closeSnackbar(): void {
    this.SET_SNACKBAR({ display: false })
  }

  @Action
  public setSelectExpansionTab(selectedTab:string):void {
    this.SET_APP_BAR(Object.assign(this._appBar, { selectedTab: selectedTab }))
  }

  @Action
  public showPrintView(): void {
    this.SET_IS_PRINT_VIEW(true)
  }

  @Action
  public hidePrintView(): void {
    this.SET_IS_PRINT_VIEW(false)
  }

  @Action
  public showGlobalError(): void {
    this.SET_GLOBAL_ERROR_VISIBLE(true)
  }

  @Action
  public hideGlobalErrorMessage(): void {
    this.SET_GLOBAL_ERROR_VISIBLE(false)
  }
}

export const structureModule = getModule(StructureStore)

@Module({ store, dynamic: true, namespaced: true, name: 'myBuildings', preserveState: isAlreadyInitialized })
class MyBuildingsStore extends VuexModule {
  private _myBuildingsGet: MyBuildingsGetResponse = new MyBuildingsGetResponse()

  get myBuildingsGet(): MyBuildingsGetResponse {
    return this._myBuildingsGet
  }

  @Mutation
  private SET_MY_BUILDINGS_GET(res: MyBuildingsGetResponse): void {
    this._myBuildingsGet = res
  }

  @Action
  async fetchMyBuildings(): Promise<void> {
    const res = await myBuildingsClient.getMyBuildings()
    this.SET_MY_BUILDINGS_GET(res)
  }
}

export const myBuildingsModule = getModule(MyBuildingsStore)
