/** 実行環境 ※モバイルも、ブラウザ利用なら WEB に該当 */
export const DEVICE_TYPE = {
  IOS: 1,
  ANDROID: 2,
  WEB: 3,
} as const
export type DeviceType = typeof DEVICE_TYPE[keyof typeof DEVICE_TYPE]

/** 実行環境 */
export const WEB_TYPE = {
  CRIOS: 1,
  GSA: 2,
  EDGIOS: 3,
  SAFARI: 4
} as const
export type WebType = typeof WEB_TYPE[keyof typeof WEB_TYPE]

/**
 * 認証状態
 * 画面導線の関係から、途中省略もありえつつ 1 -> 2 -> 3 -> 9 の順に進行する
 * LMログイン時は1 -> (11 -> 12 -> 4) -> 9 の順に進行する（※11, 12, 4は途中省略されるケースあり）
 * なりすましログイン時は 9 のまま
 * もしstateを追加した場合、下記ファイルへの追記が必要
 * 1): LoginPage.vue
 * 2): IdentificationPage.vue
 * 3): LoginCompletionPage.vue
 * 4): ExternalLoginCompletionPage.vue
 */
export const AUTH_STATE = {
  ANONYMOUS: 1,
  HAVE_TO_IDENTIFY: 2,
  HAVE_TO_INITIALIZE: 3,
  HAVE_TO_SELECT_BUILDING: 4, // LMログイン時、区分所有者の複数物件対応時に使用
  AUTHORIZED: 9,

  HAVE_TO_MFA: 11, // LMログイン用
  HAVE_TO_CAUTION: 12, // LMログイン用
} as const
export type AuthState = typeof AUTH_STATE[keyof typeof AUTH_STATE];

/**
 * ログイン種別
 */
export const LOGIN_TYPE = {
  FROM_OWNER_APPS: {
    NORMAL: 11, // 区分所有者のログイン
    DUMMY: 12, // LMログイン
  },
  FROM_ADMIN_APPS: {
    SIMULATE: 21, // なりすましログイン（管理者業務執行者/GCC/フロント）
  }
} as const
export type LoginType =
  typeof LOGIN_TYPE.FROM_OWNER_APPS[keyof typeof LOGIN_TYPE.FROM_OWNER_APPS]
  | typeof LOGIN_TYPE.FROM_ADMIN_APPS[keyof typeof LOGIN_TYPE.FROM_ADMIN_APPS]

export const LM_DUMMY_ROOM_NUMBER = 'ライフマネージャー'

/**
 * 機能種別
 */
export const FUNCTION_TYPE = {
  OWNER_IDEA_DETAIL: 110, // アイデア詳細
  ADMIN_IDEA_DETAIL: 120, // プラン詳細
  GM_RESOLUTION_DETAIL: 130, // 総会決議
  ONLINE_RESOLUTION_DETAIL: 140, // オンライン決議
  REPAIR_PLAN: 200, // 長期修繕計画トップ
  NOTIFICATIONS: 400, // お知らせ一覧
  NOTIFICATION_DETAIL: 410, // お知らせ詳細
  CONSULTATION_DETAIL: 510, // 相談詳細
  QUESTIONNAIRE_DETAIL: 901, // アンケート詳細
  QUESTIONNAIRE_REPORT_DETAIL: 902, // アンケート報告書詳細
  SIMPLE_REPAIR_PLAN: 1200, // 簡易版長期修繕計画トップ
} as const
export type FunctionType = typeof FUNCTION_TYPE [keyof typeof FUNCTION_TYPE]

/**
 * 意思決定プロセス種別
 */
export const DECISION_PROCESS_TYPE = {
  /** 凡例 */
  ALL: 0,
  /** 投稿 */
  IDEA: 10,
  /** 意見受付 */
  OPINION: 11,
  /** 賛同受付 */
  AGREEMENT: 12,
  /** 投票 */
  RESOLUTION: 13
} as const

/**
 * アイデア種別
 */
export const IDEA_TYPES = {
  /** アイデア */
  OWNER_IDEA: 1,
  /** プラン */
  ADMIN_IDEA: 2,
} as const
export type IdeaType = typeof IDEA_TYPES[keyof typeof IDEA_TYPES]

/**
 * アイデア状態
 */
export const IDEA_STATES = {
  /** アイデア */
  OWNER: {
    /** アイデア */
    POSTED: 11,
    /** 採用済み */
    ADOPTED: 12,
    /** アーカイブ */
    REJECTED: 13,
    /** キャンセル済み */
    CANCELED: 18,
    /** 削除済み */
    DELETED: 19,
  },
  /** プラン */
  ADMIN: {
    /** 賛同受付中 */
    ACCEPTING_AGREEMENT: 21,
    /** 賛同数集計中 */
    COUNTING: 22,
    /** 結果公開済み */
    RELEASED: 23,
    /** 意見受付中 */
    ACCEPTING_OPINION: 24,
    /** 削除済み */
    DELETED: 29,
  },
} as const

export type IdeaState =
  typeof IDEA_STATES.ADMIN[keyof typeof IDEA_STATES.ADMIN]
  |typeof IDEA_STATES.OWNER[keyof typeof IDEA_STATES.OWNER]

/**
 * 共通関数用のMap
 * ラベルやアーカイブを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const IDEA_STATE_MAP = new Map([
  [IDEA_STATES.OWNER.POSTED,
    { LABEL: 'アイデア', IS_ARCHIVED: false, IS_DELETED: false, PROCESS: DECISION_PROCESS_TYPE.IDEA }
  ],
  [IDEA_STATES.OWNER.ADOPTED,
    { LABEL: 'アーカイブ', IS_ARCHIVED: true, IS_DELETED: false, PROCESS: DECISION_PROCESS_TYPE.IDEA }
  ],
  [IDEA_STATES.OWNER.REJECTED,
    { LABEL: 'アーカイブ', IS_ARCHIVED: true, IS_DELETED: false, PROCESS: DECISION_PROCESS_TYPE.IDEA }
  ],
  [IDEA_STATES.OWNER.CANCELED,
    { LABEL: '削除済み', IS_ARCHIVED: false, IS_DELETED: true, PROCESS: DECISION_PROCESS_TYPE.IDEA }
  ],
  [IDEA_STATES.OWNER.DELETED,
    { LABEL: '削除済み', IS_ARCHIVED: false, IS_DELETED: true, PROCESS: DECISION_PROCESS_TYPE.IDEA }
  ],
  [IDEA_STATES.ADMIN.ACCEPTING_AGREEMENT,
    { LABEL: '賛同受付中', IS_ARCHIVED: false, IS_DELETED: false, PROCESS: DECISION_PROCESS_TYPE.AGREEMENT }
  ],
  [IDEA_STATES.ADMIN.ACCEPTING_OPINION,
    { LABEL: '意見受付中', IS_ARCHIVED: false, IS_DELETED: false, PROCESS: DECISION_PROCESS_TYPE.OPINION }
  ],
  [IDEA_STATES.ADMIN.COUNTING,
    { LABEL: '賛同数集計中', IS_ARCHIVED: true, IS_DELETED: false }
  ],
  [IDEA_STATES.ADMIN.RELEASED,
    { LABEL: 'アーカイブ', IS_ARCHIVED: true, IS_DELETED: false }
  ],
  [IDEA_STATES.ADMIN.DELETED,
    { LABEL: '削除済み', IS_ARCHIVED: false, IS_DELETED: true }
  ],
])

/**
 *  賛同・意見アイテム種別
 */
export const ADMIN_IDEA_ITEM_TYPES = {
  /** 案 */
  PLAN: 1,
  /** 賛同数集計結果 */
  COUNTING_RESULT: 2,
  /** 意見交換会のご案内 */
  MEETING: 3,
  /** 意見交換会の結果 */
  MEETING_SUMMARY: 4,
} as const

export type AdminIdeaItemType = typeof ADMIN_IDEA_ITEM_TYPES[keyof typeof ADMIN_IDEA_ITEM_TYPES]

/**
 * 決議種別
 */
export const RESOLUTION_TYPES = {
  /** 総会 */
  GENERAL_MEETING: 1,
  /** オンライン決議 */
  ONLINE: 2,
} as const

export type ResolutionType = typeof RESOLUTION_TYPES[keyof typeof RESOLUTION_TYPES]

/**
 * 決議状態
*/
export const RESOLUTION_STATES = {
  /** 総会 */
  GENERAL_MEETING: {
    /** 意思表明・質問受付中 */
    ACCEPTING_ALL: 11,
    /** 意思表明受付中、質問締切 */
    ACCEPTING_STATEMENT: 12,
    /** 結果集計中 */
    COUNTING: 13,
    /** 結果公開済み */
    RELEASED: 14,
    /** 削除済み */
    DELETED: 19,
  },
  /** オンライン決議 */
  ONLINE: {
    /** 投票・質問受付中 */
    ACCEPTING_ALL: 21,
    /** 投票受付中、質問締切 */
    ACCEPTING_VOTE: 22,
    /** 結果集計中 */
    COUNTING: 23,
    /** 結果公開 */
    RELEASED: 24,
    /** 削除済み */
    DELETED: 29,
  },
} as const

export type ResolutionState =
  typeof RESOLUTION_STATES.GENERAL_MEETING[keyof typeof RESOLUTION_STATES.GENERAL_MEETING]
  |typeof RESOLUTION_STATES.ONLINE[keyof typeof RESOLUTION_STATES.ONLINE]

/**
 * 共通関数用のMap
 * ラベルやアーカイブを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const RESOLUTION_STATE_MAP = new Map([
  [RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_ALL,
    { LABEL: '意思表明受付中', IS_ARCHIVED: false, IS_ACTIONABLE: true, IS_QUESTIONABLE: true, IS_RELEASED: false, IS_DELETED: false }
  ],
  [RESOLUTION_STATES.GENERAL_MEETING.ACCEPTING_STATEMENT,
    { LABEL: '意思表明受付中', IS_ARCHIVED: false, IS_ACTIONABLE: true, IS_QUESTIONABLE: false, IS_RELEASED: false, IS_DELETED: false }
  ],
  [RESOLUTION_STATES.GENERAL_MEETING.COUNTING,
    { LABEL: '結果集計中', IS_ARCHIVED: false, IS_ACTIONABLE: false, IS_QUESTIONABLE: false, IS_RELEASED: false, IS_DELETED: false }
  ],
  [RESOLUTION_STATES.GENERAL_MEETING.RELEASED,
    { LABEL: 'アーカイブ', IS_ARCHIVED: true, IS_ACTIONABLE: false, IS_QUESTIONABLE: false, IS_RELEASED: true, IS_DELETED: false }
  ],
  [RESOLUTION_STATES.GENERAL_MEETING.DELETED,
    { LABEL: '削除済', IS_ARCHIVED: false, IS_ACTIONABLE: false, IS_QUESTIONABLE: false, IS_RELEASED: false, IS_DELETED: true }
  ],
  [RESOLUTION_STATES.ONLINE.ACCEPTING_ALL,
    { LABEL: '投票受付中', IS_ARCHIVED: false, IS_ACTIONABLE: true, IS_QUESTIONABLE: true, IS_RELEASED: false, IS_DELETED: false }
  ],
  [RESOLUTION_STATES.ONLINE.ACCEPTING_VOTE,
    { LABEL: '投票受付中', IS_ARCHIVED: false, IS_ACTIONABLE: true, IS_QUESTIONABLE: false, IS_RELEASED: false, IS_DELETED: false }
  ],
  [RESOLUTION_STATES.ONLINE.COUNTING,
    { LABEL: '結果集計中', IS_ARCHIVED: true, IS_ACTIONABLE: false, IS_QUESTIONABLE: false, IS_RELEASED: false, IS_DELETED: false }
  ],
  [RESOLUTION_STATES.ONLINE.RELEASED,
    { LABEL: 'アーカイブ', IS_ARCHIVED: true, IS_ACTIONABLE: false, IS_QUESTIONABLE: false, IS_RELEASED: true, IS_DELETED: false }
  ],
  [RESOLUTION_STATES.ONLINE.DELETED,
    { LABEL: '削除済', IS_ARCHIVED: false, IS_ACTIONABLE: false, IS_QUESTIONABLE: false, IS_RELEASED: false, IS_DELETED: true }
  ],
])

/**
 * 意思表明種別
*/
export const STATEMENT_TYPES = {
  /** 会場で出席する */
  PRESENT_LOCAL: 1,
  /** 委任する */
  DELEGATE: 2, // 機能改善⑧ 追加開発No.73の対応で委任は削除することになったが、データには残っているため画面表示に使用する
  /** 議決権行使する */
  VOTE: 3,
  /** リモートで出席する */
  PRESENT_REMOTE: 4,
} as const

export type StatementType = typeof STATEMENT_TYPES[keyof typeof STATEMENT_TYPES]

/**
 * 開催方法種別
*/
export const HOLDING_METHOD_TYPE = {
  /** 会場のみ */
  LOCAL: 1,
  /** リモートのみ */
  REMOTE: 2,
  /** 会場＋リモート両方 */
  BOTH: 3,
} as const
export type HoldingMethodType = typeof HOLDING_METHOD_TYPE[keyof typeof HOLDING_METHOD_TYPE]

/**
 * 投票種別
*/
export const VOTE_TYPES = {
  /** 白票 */
  BLANK: 0,
  /** 賛成 */
  FOR: 1,
  /** 反対 */
  AGAINST: 2,
} as const

export type VoteType = typeof VOTE_TYPES[keyof typeof VOTE_TYPES]

/**
 * 共通関数用のMap
 * ラベルを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const VOTE_TYPE_MAP = new Map([
  [VOTE_TYPES.BLANK, { }],
  [VOTE_TYPES.FOR, { LABEL: '賛成' }],
  [VOTE_TYPES.AGAINST, { LABEL: '反対' }],
])

/**
 * 素材種別
 */
export const MATERIAL_TYPES = {
  /** 画像 */
  IMAGE: 1,
  /** 動画 */
  VIDEO: 2,
  /** PDF */
  PDF: 3,
} as const

export type MaterialType = typeof MATERIAL_TYPES[keyof typeof MATERIAL_TYPES]

/**
 * お知らせ構成要素種別
 */
export const NOTIFICATION_ELEMENT_TYPES = {
  /** 本文 */
  BODY: 10,
  /** 添付 */
  ATTACHMENT: 20,

  /** リンク */
  LINK: {
    /** smooth-e */
    SMOOTH_E: 31,
    /** 外部サイト */
    EXTERNAL: 32,
    /** メール */
    EMAIL: 33,
    /** 電話番号 */
    PHONE_NUMBER: 34,
  },

  /** 見出し */
  HEADING: {
    /** レベル2 */
    LEVEL2: 42,
    /** レベル3 */
    LEVEL3: 43,
    /** レベル4 */
    LEVEL4: 44,
  },

  /** 箇条書き */
  BULLET_POINT: {
    /** 黒ポチ（順序なし） */
    DOT: 51,
    /** 記号（順序あり） */
    INDEX: 52,
  },

  /** 横線 */
  HORIZON: 60,

  /** 角丸四角   */
  ROUNDED_SQUARE: {
    /** 薄い灰色 */
    LIGHT_GRAY: 71,
  },

  /** ボタン */
  BUTTON: {
    /** アイデア投稿 */
    POST_IDEA: 81,
    /** 相談・連絡 */
    CONSULTATION: 82,

  },
} as const
export type NotificationElementType = typeof NOTIFICATION_ELEMENT_TYPES.BODY[keyof typeof NOTIFICATION_ELEMENT_TYPES.BODY]
| typeof NOTIFICATION_ELEMENT_TYPES.ATTACHMENT[keyof typeof NOTIFICATION_ELEMENT_TYPES.ATTACHMENT]
| typeof NOTIFICATION_ELEMENT_TYPES.LINK[keyof typeof NOTIFICATION_ELEMENT_TYPES.LINK]
| typeof NOTIFICATION_ELEMENT_TYPES.HEADING[keyof typeof NOTIFICATION_ELEMENT_TYPES.HEADING]
| typeof NOTIFICATION_ELEMENT_TYPES.BULLET_POINT[keyof typeof NOTIFICATION_ELEMENT_TYPES.BULLET_POINT]
| typeof NOTIFICATION_ELEMENT_TYPES.HORIZON[keyof typeof NOTIFICATION_ELEMENT_TYPES.HORIZON]
| typeof NOTIFICATION_ELEMENT_TYPES.ROUNDED_SQUARE[keyof typeof NOTIFICATION_ELEMENT_TYPES.ROUNDED_SQUARE]
| typeof NOTIFICATION_ELEMENT_TYPES.BUTTON[keyof typeof NOTIFICATION_ELEMENT_TYPES.BUTTON]

/**
 * お知らせ受信設定種別
 */
export const NOTIFICATION_SETTING_TYPES = {
  /** すべて */
  ALL: 1,
  /** 一部 */
  SOME: 2,
  /** 最小限 */
  MINIMUM: 3,
} as const

export type NotificationSettingType = typeof NOTIFICATION_SETTING_TYPES[keyof typeof NOTIFICATION_SETTING_TYPES]

/**
 * お知らせ本文（リスト）
 * 階層種別
 */
export const HIERARCHY_TYPES = {
  FIRST_HIERARCHY: 1,
  SECOND_HIERARCHY: 2,
  THIRD_HIERARCHY: 0
} as const
export type HierarchyTypes = typeof HIERARCHY_TYPES[keyof typeof HIERARCHY_TYPES]

/**
 * お知らせ本文（リスト）用MAP
 * 階層種別に紐づくli用の種類（記号）
 */
export const HIERARCHY_TYPE_DOT_MAP = new Map([
  [HIERARCHY_TYPES.FIRST_HIERARCHY, 'disc'],
  [HIERARCHY_TYPES.SECOND_HIERARCHY, 'circle'],
  [HIERARCHY_TYPES.THIRD_HIERARCHY, 'square']
])

/**
 * お知らせ本文（リスト）用MAP
 * 階層種別に紐づくli用の種類（順番）
 */
export const HIERARCHY_TYPE_INDEX_MAP = new Map([
  [HIERARCHY_TYPES.FIRST_HIERARCHY, 'decimal'],
  [HIERARCHY_TYPES.SECOND_HIERARCHY, 'lower-alpha'],
  [HIERARCHY_TYPES.THIRD_HIERARCHY, 'lower-roman']
])

/**
 * 長計タブ
 */
export const REPAIR_PLAN_TABS = {
  summary: {
    tab: 'tab1',
    name: '収支計画'
  },
  detail: {
    tab: 'tab2',
    name: '工事計画'
  }
} as const

/**
 * 長期修繕計画種別
 */
export const REPAIR_PLAN_TYPES = {
  /** リアルタイム長計 */
  REPAIR_PLAN: 1,
  /** 簡易版リアルタイム長計 */
  SIMPLE_REPAIR_PLAN: 2,
} as const
export type RepairPlanType = typeof REPAIR_PLAN_TYPES[keyof typeof REPAIR_PLAN_TYPES]

/**
 * GA 未ログイン者の情報
 */
export const GA_ANONYMOUS = 'anonymous'

/**
 * GA専用のユーザカテゴリー
 * ログイン種別(LOGIN_TYPE)と同じ分け方だが、GAはログイン種別実装前に既に稼働していたため、
 * ログイン種別とは別になるが既に使用しているコード体系を引き続き利用する
 * （このコード値をGA以外で使用しないこと）
 */
export const GA_USER_CATEGORY = {
  OWNER: 1,
  ADMIN_DUMMY: 5, // LMログイン
  ADMIN_SIMULATE: 6, // GCC・FR・管理者業務執行者ログイン
}
export type GaUserCategory = typeof GA_USER_CATEGORY[keyof typeof GA_USER_CATEGORY]

/**
 * LM認証時エラーメッセージ
 */
export const DUMMY_AUTH_ERROR_MESSAGE = {
  USER_DISABLED: 'このアカウントはご利用になれません。',
  ACCOUNT_LOCKED: '複数回ログインに失敗したため、アカウントをロックしました。ロック中は新たなログインができません。しばらく時間をおいてから、再度お試しください。',
  INVALID_CREDENTIAL: 'IDまたはパスワードが正しくありません。',

  INVALID_PASSWORD: '入力された文字列はパスワードとして使用できません。',
  INVALID_CURRENT_PASSWORD: '現在のパスワードが正しくありません。',
  PASSWORD_CHANGE_LOCKED: 'アカウントをロックしました。ロック中はパスワード変更ができません。しばらく時間をおいてから、再度お試しください。',

  MFA_NOT_SET: '現在このアカウントではログインできません。管理者にお問い合わせください。',

  MFA_TIMEOUT: '認証キーの有効期限が切れています。SMSの再受信を行ってください。',
  INVALID_MFA: '認証キーが正しくありません。',

  NO_BUILDING_ASSIGNED: '閲覧可能な物件がありません。管理者にお問い合わせください。',

  UNEXPECTED_ERROR: '予期せぬエラーが発生しました。時間を置いてから再度お試しいただくか、お問い合わせください。',
}

/**
 * アンケートステータス
 */
export const QUESTIONNAIRE_STATE = {
  /**
   * 公開済
   */
  OPEN: 2,
  /**
   * 回答締切済
   */
  COUNTING: 3,
  /**
   * 報告書作成済
   */
  RELEASED: 4
} as const

export type QuestionnaireState = typeof QUESTIONNAIRE_STATE[keyof typeof QUESTIONNAIRE_STATE]

/**
 * アンケートセクション種別
 */
export const QUESTIONNAIRE_SECTION_TYPE = {
  /**
   * 回答
   */
  ANSWER: 1,
  /**
   * ご意見・ご要望
   */
  OPINIONS_AND_REQUESTS: 2
} as const
export type QuestionnaireSectionType = typeof QUESTIONNAIRE_SECTION_TYPE[keyof typeof QUESTIONNAIRE_SECTION_TYPE]

/**
 * アンケート設問種別
 */
export const QUESTIONNAIRE_QUESTION_TYPE = {
  /**
   * 自由入力
   */
  FREE_TEXT: 1,
  /**
   * 単一選択
   */
  SINGLE_SELECTION: 2,
  /**
   * 複数選択
   */
  MULTIPLE_SELECTIONS: 3
} as const
export type QuestionnaireQuestionType = typeof QUESTIONNAIRE_QUESTION_TYPE[keyof typeof QUESTIONNAIRE_QUESTION_TYPE]

/**
 * アンケート設問構成要素種別
 */
export const QUESTIONNAIRE_QUESTION_ELEMENT_TYPES = {
  /**
   * 自由入力（入力式）
   */
  FREE: 0,
  /**
   * 選択肢（選択式）
   */
  SELECTABLE_OPTION: 1,
  /**
   * その他（選択式）
   */
  OTHER_OPTION: 2,
} as const
export type QuestionnaireQuestionElementType = typeof QUESTIONNAIRE_QUESTION_ELEMENT_TYPES[keyof typeof QUESTIONNAIRE_QUESTION_ELEMENT_TYPES]

/**
 * アンケートステータス 共通関数用のMap
 * ラベルやアーカイブを使いたい場合はこのMapを直接使わず、libs配下の共通関数を使うこと
 */
export const QUESTIONNAIRE_STATE_MAP = new Map([
  [QUESTIONNAIRE_STATE.OPEN,
    { REQUIRE_ANSWER: true, UNREAD_VISIBLE: true }
  ],
  [QUESTIONNAIRE_STATE.COUNTING,
    { REQUIRE_ANSWER: false, UNREAD_VISIBLE: false }
  ],
  [QUESTIONNAIRE_STATE.RELEASED,
    { REQUIRE_ANSWER: false, UNREAD_VISIBLE: false }
  ],
])

export const APP_VERSION_ERROR_MESSAGE = 'アプリのバージョンが古いため、操作を実行できませんでした。アップデートを行ってください。'

/**
 * アンケートグラフ種別
 */
export const CHART_TYPES = {
  /**
   * 帯グラフ
   */
  HORIZONTAL: 1,

  /**
   * 円グラフ
   */
  PIE: 2,
} as const
export type ChartType = typeof CHART_TYPES[keyof typeof CHART_TYPES]
