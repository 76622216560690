<template>
  <svg
    class="sm__svg"
    height="24px"
    width="24px"
  >
    <path
      d="M19.3965 22C19.9465 22 20.4173 21.8042 20.809 21.4125C21.2007 21.0208 21.3965 20.55 21.3965 20V4C21.3965 3.45 21.2007 2.97917 20.809 2.5875C20.4173 2.19583 19.9465 2 19.3965 2H13.9981C13.7147 2 13.4772 2.09583 13.2856 2.2875C13.0939 2.47917 12.9981 2.71667 12.9981 3C12.9981 3.28333 13.0939 3.52083 13.2856 3.7125C13.4772 3.90417 13.7147 4 13.9981 4H19.3965V20H8.39648L8.39648 18.5C8.39648 18.2167 8.30064 17.9792 8.10898 17.7875C7.91731 17.5958 7.67981 17.5 7.39648 17.5C7.11314 17.5 6.87564 17.5958 6.68398 17.7875C6.49231 17.9792 6.39648 18.2167 6.39648 18.5L6.39648 20C6.39648 20.55 6.59232 21.0208 6.98398 21.4125C7.37565 21.8042 7.84648 22 8.39648 22H19.3965ZM16.3965 18C16.6798 18 16.9173 17.9042 17.109 17.7125C17.3007 17.5208 17.3965 17.2833 17.3965 17C17.3965 16.7167 17.3007 16.4792 17.109 16.2875C16.9173 16.0958 16.6798 16 16.3965 16H11.3965C11.1132 16 10.8757 16.0958 10.684 16.2875C10.4923 16.4792 10.3965 16.7167 10.3965 17C10.3965 17.2833 10.4923 17.5208 10.684 17.7125C10.8757 17.9042 11.1132 18 11.3965 18H16.3965ZM16.3965 14C16.6798 14 16.9173 13.9042 17.109 13.7125C17.3007 13.5208 17.3965 13.2833 17.3965 13C17.3965 12.7167 17.3007 12.4792 17.109 12.2875C16.9173 12.0958 16.6798 12 16.3965 12H13.3965C13.1132 12 12.8757 12.0958 12.684 12.2875C12.4923 12.4792 12.3965 12.7167 12.3965 13C12.3965 13.2833 12.4923 13.5208 12.684 13.7125C12.8757 13.9042 13.1132 14 13.3965 14H16.3965Z"
    />
    <path
      d="M7 15C6.70115 15 6.43103 14.9323 6.18966 14.7969C5.94828 14.6615 5.75862 14.48 5.62069 14.2525C5.24138 14.2525 4.91667 14.1252 4.64655 13.8706C4.37644 13.616 4.24138 13.31 4.24138 12.9525V10.645C3.56322 10.2225 3.02012 9.66458 2.61207 8.97125C2.20402 8.27792 2 7.525 2 6.7125C2 5.40167 2.48563 4.28854 3.4569 3.37312C4.42816 2.45771 5.6092 2 7 2C8.3908 2 9.57184 2.45771 10.5431 3.37312C11.5144 4.28854 12 5.40167 12 6.7125C12 7.54667 11.796 8.305 11.3879 8.9875C10.9799 9.67 10.4368 10.2225 9.75862 10.645V12.9525C9.75862 13.31 9.62356 13.616 9.35345 13.8706C9.08333 14.1252 8.75862 14.2525 8.37931 14.2525C8.24138 14.48 8.05172 14.6615 7.81035 14.7969C7.56897 14.9323 7.29885 15 7 15ZM5.62069 12.9525H8.37931V12.3675H5.62069V12.9525ZM5.62069 11.7175H7H8.37931V11.1H5.62069V11.7175ZM5.48276 9.8H6.48276V8.045L5.32759 6.95625C5.22414 6.85875 5.17241 6.745 5.17241 6.615C5.17241 6.485 5.22414 6.37125 5.32759 6.27375C5.43103 6.17625 5.55172 6.1275 5.68966 6.1275C5.82759 6.1275 5.94828 6.17625 6.05172 6.27375L7 7.1675L7.94828 6.27375C8.05172 6.17625 8.17241 6.1275 8.31035 6.1275C8.44828 6.1275 8.56897 6.17625 8.67241 6.27375C8.77586 6.37125 8.82759 6.485 8.82759 6.615C8.82759 6.745 8.77586 6.85875 8.67241 6.95625L7.51724 8.045V9.8H8.51724C9.13793 9.51833 9.64368 9.10396 10.0345 8.55688C10.4253 8.00979 10.6207 7.395 10.6207 6.7125C10.6207 5.75917 10.2701 4.95208 9.56897 4.29125C8.86782 3.63042 8.01149 3.3 7 3.3C5.98851 3.3 5.13218 3.63042 4.43103 4.29125C3.72989 4.95208 3.37931 5.75917 3.37931 6.7125C3.37931 7.395 3.57471 8.00979 3.96552 8.55688C4.35632 9.10396 4.86207 9.51833 5.48276 9.8Z"
    />

  </svg>
</template>
<style scoped>
.sm__svg {
     fill:currentColor;
}
</style>
